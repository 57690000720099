import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { A11y, Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import pageArrowLeft from '../../assets/icons/icon_pagenate_arrow_left_white.png';
import pageArrowRight from '../../assets/icons/icon_pagenate_arrow_right_white.png';
import { BLACK, GRAY_00, GRAY_01, GRAY_04, WHITE } from '../../constants/colors';
import { SECTION_HEIGHT, WINDOW_MIN_WIDTH } from '../../constants/layout';
import { ANCHOR_IDS } from '../../constants/urls';
import { ZIndex } from '../../constants/zIndex';
import { HeaderMenuType, HeaderStyleType } from '../../data/header';
import { slideData } from '../../data/petmily';
import useChangeHeaderStyle from '../../hooks/useChangeHeaderStyle';
import { UrbaneFont } from '../../styles/common';
import { getDesktopStyle, showOnDesktopStyle, showOnMobileStyle } from '../../styles/layout';
import { swiperPagenationStyle } from '../../styles/swiper';
import { pxToRem } from '../../utils/common';
import { isMobile } from '../../utils/userAgent';
import AnchorLink from '../AnchorLink';
import SectionTitleSet from '../common/SectionTitleSet';

const PetmilyLabSection = () => {
	const [titleSetColor, setTitleSetColor] = useState<'BLACK' | 'WHITE'>('WHITE');
	const [currentIndex, setCurrentIndex] = useState(0);

	const sectionRef = useRef<HTMLElement>(null);
	useChangeHeaderStyle({
		sectionRef,
		currentType: HeaderMenuType.Story,
		nextType: HeaderMenuType.Businesses,
		currentStyleType: HeaderStyleType.Black,
		nextStyleType: HeaderStyleType.White,
	});

	useEffect(() => {
		setTitleSetColor(isMobile() ? 'BLACK' : 'WHITE');
	}, []);

	return (
		<>
			<AnchorLink id={ANCHOR_IDS.VPETMILYLAB} />
			<Wrapper ref={sectionRef}>
				<SwiperDesktop>
					<Swiper
						modules={[Navigation, Pagination, A11y]}
						slidesPerView={1}
						navigation
						pagination={{
							clickable: true,
							bulletClass: 'swipter-pagination-petmily-bullet',
							bulletActiveClass: 'swipter-pagination-petmily-bullet-active',
							renderBullet: function (index, className) {
								return `<span class="${className}"></span>`;
							},
						}}
						onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex)}
						loop
					>
						{slideData.map((data) => (
							<SwiperSlide key={data.id}>
								<SlideWrapper>
									<SlideImg src={data.image} alt="" />
									<SlideShade />
									<SlideText>{data.text}</SlideText>
								</SlideWrapper>
							</SwiperSlide>
						))}
					</Swiper>
				</SwiperDesktop>
				<TitleWrapper>
					<SectionTitleSet
						title="V+ Petmily Lab."
						description={
							<>
								<span css={showOnDesktopStyle}>
									“투명한 정보 제공과 국내 펫푸드 시장에 대한 인식 변화를 위해 연구·개발”
									<br />
									이라는 공통 목표를 지향하는 연구소
								</span>
								<span css={showOnMobileStyle}>
									국내 펫푸드 시장의 인식개선을 위해
									<br />
									공통의 목표를 지향하는 연구소입니다.
								</span>
							</>
						}
						textAlign="CENTER"
						color={titleSetColor}
					/>
				</TitleWrapper>
				<CurrentIndexNumber>0{currentIndex + 1}</CurrentIndexNumber>
				<AllIndexNumber>0{slideData.length}</AllIndexNumber>
				<SwiperMobile>
					<Swiper
						modules={[Pagination, A11y, Autoplay]}
						loop={true}
						pagination={{
							clickable: true,
						}}
						autoplay={{
							delay: 2000,
							disableOnInteraction: false,
						}}
					>
						{slideData.map((data) => (
							<SwiperSlide key={data.id}>
								<SlideWrapper>
									<SlideImg src={data.image} alt="" />
									<SlideShade />
									<SlideText>{data.text}</SlideText>
								</SlideWrapper>
							</SwiperSlide>
						))}
					</Swiper>
				</SwiperMobile>
			</Wrapper>
		</>
	);
};

export default PetmilyLabSection;

const IndexStyle = css`
	${UrbaneFont};
	font-size: ${pxToRem(13)};
	font-weight: 600;
	position: absolute;
	bottom: ${pxToRem(116)};
	left: 50%;
	z-index: ${ZIndex.PageIndexNumber};
`;

const PageNavButtonStyle = css`
	display: flex;
	justify-content: space-between;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: ${ZIndex.PageNavButton};
	width: ${pxToRem(26)};
	height: ${pxToRem(34)};
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;

	&:after {
		content: none;
	}
`;

const AllIndexNumber = styled.p`
	${showOnDesktopStyle};
	${getDesktopStyle(css`
		${IndexStyle};
		transform: translateX(calc(-50% + ${pxToRem(180)}));
	`)}
`;

const CurrentIndexNumber = styled.p`
	${showOnDesktopStyle};
	${getDesktopStyle(css`
		${IndexStyle};
		transform: translateX(calc(-50% - ${pxToRem(180)}));
	`)}
`;

const SlideText = styled.p`
	position: absolute;
	top: ${pxToRem(21)};
	width: 100%;
	text-align: center;
	font-weight: 700;
	font-size: ${pxToRem(13)};
	line-height: ${pxToRem(20)};
	color: ${WHITE};
	white-space: pre-line;

	${getDesktopStyle(css`
		white-space: normal;
		top: initial;
		bottom: ${pxToRem(152)};
		font-weight: 500;
		font-size: ${pxToRem(20)};
		color: ${GRAY_01};
	`)}
`;

const SlideShade = styled.div`
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	mix-blend-mode: multiply;
	background: linear-gradient(
		180deg,
		rgba(255, 242, 238, 0) 0%,
		${BLACK} 0.01%,
		rgba(255, 242, 238, 0) 34.73%,
		${BLACK} 99.27%
	);
`;

const SlideImg = styled.img`
	height: 100%;
	object-fit: cover;
`;

const SlideWrapper = styled.div`
	position: relative;
	height: 87.2vw;

	${getDesktopStyle(css`
		height: ${pxToRem(SECTION_HEIGHT)};
	`)}
`;

const TitleWrapper = styled.div`
	padding-top: ${pxToRem(80)};
	padding-bottom: ${pxToRem(44)};
	margin: 0 auto;

	${getDesktopStyle(css`
		position: absolute;
		top: ${pxToRem(114)};
		width: 100%;
		padding: 0;
		z-index: ${ZIndex.SlideTitle};
	`)}
`;

const SwiperMobile = styled.div`
	${showOnMobileStyle};

	${swiperPagenationStyle({ bgColor: GRAY_04, activeColor: WHITE, bottom: 20 })};
`;

const SwiperDesktop = styled.div`
	${showOnDesktopStyle};
	${getDesktopStyle(css`
		.swiper-pagination-bullets {
			bottom: ${pxToRem(123)};
			display: flex;
			justify-content: center;
			z-index: ${ZIndex.PageNavBullet};
		}

		.swipter-pagination-petmily-bullet {
			display: block;
			width: ${pxToRem(65)};
			height: ${pxToRem(5)};
			background-color: ${GRAY_00};
			border-radius: 0;
			opacity: 1;
			margin: 0;
			cursor: pointer;
		}

		.swipter-pagination-petmily-bullet-active {
			background-color: ${BLACK};
		}

		.swiper-button-prev {
			${PageNavButtonStyle};
			transform: translateX(calc(-50% - ${pxToRem(600)}));
			background-image: url(${pageArrowLeft});
		}

		.swiper-button-next {
			${PageNavButtonStyle};
			transform: translateX(calc(-50% + ${pxToRem(600)}));
			background-image: url(${pageArrowRight});
		}
	`)}
`;

const Wrapper = styled.section`
	color: ${WHITE};
	background-color: ${GRAY_01};

	${getDesktopStyle(css`
		position: relative;
		min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
		height: ${pxToRem(SECTION_HEIGHT)};
		color: ${GRAY_01};
		text-align: center;
	`)}
`;
